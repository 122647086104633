module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JFE Engineering Indonesia","short_name":"JFEEI","start_url":"/","background_color":"#ffffff","theme_color":"#149be1","display":"standalone","icon":"src/multimedia/jfeei-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a05ae5b070a14206d1f4d7793e0e66d7"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":{"quality":100},"linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"disableBgImage":true,"backgroundColor":"none","maxWidth":650,"showCaptions":false,"markdownCaptions":false,"quality":50,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#0073AF","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
